import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import { Helmet } from 'react-helmet'

const Fallback = (props) => {
  console.warn(`No component found for: ${props.blocktype}`)
  return false
}

export default function Homepage(props) {
  const { homepageDiners } = props.data

  return (
    <Layout {...Homepage}>
      {homepageDiners.pageSections.map((block, i) => {
        const Component = sections[block.blocktype] || Fallback
        return <Component key={block.id} index={i} {...block} />
      })}
    </Layout>
  )
}

export const query = graphql`
  {
    homepageDiners {
      id
      pageName
      pageSections {
        id
        blocktype
          ...HeroSlidesContent
          ...ParallaxSectionContent
          ...SectionContent
          ...CtaContent
      }
    }
  }
`
